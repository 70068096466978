import React from "react"
import styled from "styled-components"
// import Title from "../components/Title"
import Form from "../components/Form"
import ContactDetails from "../components/ContactDetails"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const contact = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Container className="section" id="contact">
        <div className="section-center">
          {/* <Title subtitle="Get" title="In Touch" /> */}
          <div className="contact-content">
            <Form />
            <ContactDetails />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

const Container = styled.section`
  width: 100%;

  .section-center {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
  }
  .contact-content {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 800px) {
    .contact-content {
      display: flex;
      flex-direction: row;
    }
  }
`

export default contact
