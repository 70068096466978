import React from "react"
import styled from "styled-components"

const Form = () => {
  return (
    <Container
      className="contact-form"
      action="https://formspree.io/f/xayvdvep"
      method="POST"
    >
      <h3 className="contact-title">Get In Touch</h3>
      <div className="form-center">
        <input
          type="text"
          name="name"
          id=""
          className="input name"
          placeholder="Your Name"
        />
        <input
          type="email"
          name="email"
          id=""
          className="input email"
          placeholder="Your Email"
        />
        <input
          type="tel"
          name="tel"
          id=""
          className="input tel"
          placeholder="Your Phone"
        />
        <textarea
          name="message"
          id=""
          cols="30"
          rows="10"
          className="input message"
          placeholder="Write a Message..."
        ></textarea>
      </div>
      <div className="btn-container">
        <button type="submit" className="btn form-btn">
          Send message
        </button>
      </div>
    </Container>
  )
}

const Container = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;

  .contact-title {
    font-weight: 200;
    margin: 2rem 0 4rem;
  }
  .form-center {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
  }
  .input {
    padding: 1rem 1.25rem;
    color: #ffffff;
    background: #333333;
    border: none;
    border-radius: 4px;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-family: "Nunito Sans", sans-serif;
  }
  textarea {
    resize: none;
  }
  input:focus,
  textarea:focus {
    outline: none;
  }
  .btn-container {
    width: 50%;
  }
  .form-btn {
    width: 100%;
    color: #181818;
    background-color: #17b8bd;
    transition: 0.125s;

    &:hover {
      transition: 0.125s;
      background-color: #e5f4f4;
    }
  }

  @media (min-width: 800px) {
    .form-center {
      margin-bottom: 4rem;
    }
    .form-btn {
      margin-bottom: 0;
    }
    .btn-container {
      width: 30%;
    }
  }
`

export default Form
